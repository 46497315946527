@import '_variables.less';

.mega-menu-overlay {
    position: relative;
    z-index: 2;
}

.mega-menu-overlay-content {
    padding-block: 1.5rem;
    padding-inline: 2.5rem;
    background-color: @White;
    position: absolute;
    width: 100%;
    box-shadow:
        0px 60px 80px -4px @Purple-900-o16,
        0px 1px 0px @Purple-900-o08;
}

.eb-fade-in {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.25s linear;
}

.eb-fade-out {
    visibility: hidden;
    opacity: 0;
    transition:
        visibility 0s 0.125s,
        opacity 0.125s linear;

    .mega-menu-overlay-content {
        left: -100000px;
        img {
            display: none;
        }
    }
}

.topbar-link {
    margin: 0;

    @media only screen and (min-width: @screen-md-min) {
        margin-left: 0.25rem;
        margin-right: 0.5rem;
    }
}

.search-overlay-panel {
    width: 100vw;
    background-color: @White;
}

.search-overlay-panel-content {
    padding: 1.5rem 2.5rem;
    border-bottom: 1px solid @overlay-border-color;
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 1rem;
    margin: 0 auto;
    max-width: 1366px;
}

.eb-dropdown-item {
    height: 32px;
    padding: 8px;
    line-height: 16px;
    cursor: pointer;
    background-color: @dropdown-element-background;

    &:hover {
        background-color: @dropdown-element-background-hover;
    }

    &.eb-dropdown-item--active {
        background-color: @dropdown-element-background-active;
        cursor: auto;
    }
}

.basket--button-wrapper {
    button {
        display: flex;
        flex-direction: column;
    }
}

.basket--events-count {
    font-weight: 700;
    font-size: 21px;
    color: @Purple-900;
}

.basket--event-title {
    font-weight: 600;
    font-size: 14px;
}

.basket--events {
    max-height: 220px;
    overflow-y: auto;
    padding-right: 8px;

    div {
        padding-bottom: 8px;
    }
}

.basket--event-info {
    color: @Purple-900-o64;
}

.basket-timer {
    min-width: 46px;
}

.basket--move-button {
    margin-top: auto;
}

@media only screen and (min-width: @screen-lg-min) {
    .basket--button-wrapper {
        border: 1px solid @Purple-900-o16;
        button {
            flex-direction: row;
        }
    }

    .basket--events {
        max-height: 201px;
        overflow-y: auto;
        div {
            padding-bottom: unset;
        }
    }

    .basket--events-count {
        color: @Purple-900-o48;
        font-size: 16px;
    }

    .basket--event-title {
        font-weight: bold;
    }

    .basket--move-button {
        margin-top: unset;
    }
}
